.page-container {
  display: flex;
  min-height: 100vh;
  background: linear-gradient(245deg, #352424 -15%, #252020 15%, #242424 50%, #252a2b 85%, #2e4142 115%);
  animation: fade-in 3.5s; }
  .page-container__arrow-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%; }
    @media (max-width: 640px) {
      .page-container__arrow-container {
        display: none; } }

.page-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%; }
  .page-content__center-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%; }

.page-transition-animation {
  opacity: 1;
  filter: blur(0px);
  transform: translateX(0%);
  transform: translateY(0%); }
  .page-transition-animation--up {
    animation: slide-down 0.75s; }
  .page-transition-animation--down {
    animation: slide-up 0.75s forwards; }
  .page-transition-animation--left {
    animation: slide-right 0.75s; }
  .page-transition-animation--right {
    animation: slide-left 0.75s; }

.fade-in-animation {
  animation: fade-in 0.5s forwards; }

@keyframes slide-up {
  5% {
    transform: translateY(0%); }
  100% {
    filter: blur(4px);
    opacity: 0;
    transform: translateY(-100%); } }

@keyframes slide-down {
  5% {
    transform: translateY(0%); }
  100% {
    filter: blur(4px);
    opacity: 0;
    transform: translateY(100%); } }

@keyframes slide-left {
  5% {
    transform: translateX(0%); }
  100% {
    filter: blur(4px);
    opacity: 0;
    transform: translateX(-100%); } }

@keyframes slide-right {
  5% {
    transform: translateX(0%); }
  100% {
    filter: blur(4px);
    opacity: 0;
    transform: translateX(100%); } }

@keyframes fade-in {
  0% {
    filter: blur(4px);
    opacity: 0; }
  100% {
    filter: blur(0px);
    opacity: 1; } }

.primary-button {
  cursor: pointer;
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
  background: linear-gradient(56.35deg, #1c5c65 -90%, #484944 55%, #691d18 185%);
  transition: 0.2s;
  text-align: center; }
  .primary-button:hover {
    transform: scale(110%);
    box-shadow: 0px 0px 10px 4px rgba(255, 255, 255, 0.05); }
  .primary-button p {
    color: rgba(255, 255, 255, 0.75);
    font-weight: 600;
    text-shadow: 0px 1px 8px rgba(0, 0, 0, 0.616); }

.faded-line-seperator {
  width: 100%;
  height: 3px;
  background: radial-gradient(circle, #7dbebe 0%, rgba(125, 190, 190, 0) 100%);
  margin: 15px 0px 15px 0px; }

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 145px;
  margin-bottom: 115px; }
  @media (max-width: 768px) {
    .home-container {
      margin: 0 115px; } }
  @media (max-width: 640px) {
    .home-container {
      margin: 0 25px; } }
  .home-container__text {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 95px;
    margin-top: 20vh; }
    @media (min-width: 1824px) {
      .home-container__text {
        margin-left: 10vw; } }
    @media (max-width: 1024px) {
      .home-container__text {
        margin-left: 25px; } }
    @media (max-width: 768px) {
      .home-container__text {
        margin-left: 0px; } }
    @media (max-width: 640px) {
      .home-container__text {
        margin-left: auto;
        margin-right: auto; } }
    .home-container__text h1 {
      font-size: 72px; }
      @media (min-width: 1824px) {
        .home-container__text h1 {
          font-size: 88px; } }
      @media (max-width: 1280px) {
        .home-container__text h1 {
          font-size: 64px; } }
      @media (max-width: 1024px) {
        .home-container__text h1 {
          font-size: 48px; } }
      @media (max-width: 768px) {
        .home-container__text h1 {
          font-size: 38px; } }
      @media (max-width: 420px) {
        .home-container__text h1 {
          font-size: 30px; } }
    .home-container__text h2 {
      font-size: 36px; }
      @media (min-width: 1824px) {
        .home-container__text h2 {
          font-size: 46px; } }
      @media (max-width: 1280px) {
        .home-container__text h2 {
          font-size: 28px; } }
      @media (max-width: 1024px) {
        .home-container__text h2 {
          font-size: 24px; } }
      @media (max-width: 768px) {
        .home-container__text h2 {
          font-size: 18px; } }
      @media (max-width: 420px) {
        .home-container__text h2 {
          font-size: 18px; } }
    .home-container__text h1,
    .home-container__text h2 {
      font-weight: 400;
      margin: 0 2px;
      background: linear-gradient(265deg, rgba(158, 79, 79, 0.5) 0%, #ffffff 50%, rgba(72, 137, 151, 0.5) 175%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
    .home-container__text__line-seperator {
      width: 100%;
      margin-top: 16px;
      margin-bottom: 32px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.8); }
      @media (max-width: 420px) {
        .home-container__text__line-seperator {
          margin-bottom: 20px; } }
  .home-container__resume-button {
    margin-top: 10vh;
    text-decoration: none;
    font-size: 16px; }
    @media (min-width: 1824px) {
      .home-container__resume-button {
        font-size: 18px; } }
    @media (max-width: 1024px) {
      .home-container__resume-button {
        font-size: 12px; } }
    @media (max-width: 420px) {
      .home-container__resume-button {
        font-size: 10px; } }
  .home-container__links-container {
    width: 320px; }
    @media (min-width: 1824px) {
      .home-container__links-container {
        width: 400px; }
        .home-container__links-container .some-links__icon {
          width: 65px;
          height: 65px; } }
    @media (max-width: 1024px) {
      .home-container__links-container {
        width: 225px; }
        .home-container__links-container .some-links__icon {
          width: 40px;
          height: 40px; } }
  .home-container__boggdle-button {
    text-decoration: none;
    font-size: 16px;
    position: absolute;
    top: 75px;
    right: -125px; }
    @media (max-width: 1024px) {
      .home-container__boggdle-button {
        font-size: 12px; } }
    @media (max-width: 768px) {
      .home-container__boggdle-button {
        top: 25px;
        right: -100px; } }
    @media (max-width: 640px) {
      .home-container__boggdle-button {
        top: 15px;
        right: -15px;
        font-size: 10px; } }

.aboutme-container {
  width: 100%;
  margin-left: 145px;
  margin-right: 15px; }
  @media (max-width: 1024px) {
    .aboutme-container {
      margin-left: 125px;
      margin-right: 50px; } }
  @media (max-width: 768px) {
    .aboutme-container {
      margin: 0 95px; } }
  @media (max-width: 640px) {
    .aboutme-container {
      margin: 0 25px; } }
  .aboutme-container h1 {
    width: fit-content;
    font-size: 48px;
    font-weight: 400;
    margin: 0;
    background: linear-gradient(265deg, rgba(158, 79, 79, 0.5) -75%, #ffffff 50%, rgba(72, 137, 151, 0.5) 300%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
    @media (min-width: 1824px) {
      .aboutme-container h1 {
        font-size: 54px; } }
    @media (max-width: 1280px) {
      .aboutme-container h1 {
        font-size: 38px; } }
    @media (max-width: 1024px) {
      .aboutme-container h1 {
        font-size: 36px; } }
    @media (max-width: 420px) {
      .aboutme-container h1 {
        font-size: 32px; } }
  .aboutme-container__content {
    display: flex;
    justify-content: center;
    margin: 55px 0; }
    @media (max-width: 1024px) {
      .aboutme-container__content {
        flex-direction: column;
        align-items: center; } }
  .aboutme-container__text {
    width: 45%;
    margin-right: 25px; }
    @media (min-width: 1824px) {
      .aboutme-container__text {
        width: 40%;
        margin-right: 40px; } }
    @media (max-width: 1024px) {
      .aboutme-container__text {
        width: 100%;
        margin-bottom: 50px; } }
    @media (max-width: 768px) {
      .aboutme-container__text {
        margin-right: 0; } }
    .aboutme-container__text p {
      font-family: Arial, Helvetica, sans-serif;
      color: rgba(255, 255, 255, 0.8);
      font-size: 22px;
      font-weight: 400;
      background: linear-gradient(265deg, rgba(158, 79, 79, 0.7) -150%, #ffffff 50%, rgba(72, 137, 151, 0.7) 250%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
      @media (min-width: 1824px) {
        .aboutme-container__text p {
          font-size: 24px; } }
      @media (max-width: 1280px) {
        .aboutme-container__text p {
          font-size: 20px; } }
      @media (max-width: 1024px) {
        .aboutme-container__text p {
          font-size: 18px; } }
      @media (max-width: 420px) {
        .aboutme-container__text p {
          font-size: 16px; } }
  .aboutme-container__get-in-touch {
    position: relative;
    color: #8cd6d6;
    font-size: 20px;
    text-decoration: none;
    cursor: pointer; }
    @media (min-width: 1824px) {
      .aboutme-container__get-in-touch {
        font-size: 24px; } }
    @media (max-width: 1280px) {
      .aboutme-container__get-in-touch {
        font-size: 20px; } }
    @media (max-width: 1024px) {
      .aboutme-container__get-in-touch {
        font-size: 18px; } }
    @media (max-width: 420px) {
      .aboutme-container__get-in-touch {
        font-size: 16px; } }
    .aboutme-container__get-in-touch:hover .aboutme-container__get-in-touch-focus {
      width: 100%; }
  .aboutme-container__get-in-touch-focus {
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid #7dabbe;
    transition: 0.5s; }

.contact-container {
  width: 100%;
  margin-right: 20px; }
  .contact-container__content {
    display: flex;
    justify-content: space-evenly; }
    @media (max-width: 1024px) {
      .contact-container__content {
        flex-direction: column; } }
  .contact-container__details {
    width: 400px;
    margin-top: 75px;
    margin-left: 50px; }
    .contact-container__details h2 {
      font-size: 28px;
      font-weight: 400;
      width: fit-content;
      margin-bottom: 0px;
      margin-left: -5px; }
    .contact-container__details p {
      color: rgba(255, 255, 255, 0.8);
      font-size: 18px; }
    @media (min-width: 1824px) {
      .contact-container__details {
        width: 450px;
        margin-left: 0px; }
        .contact-container__details h2 {
          font-size: 32px; }
        .contact-container__details p {
          font-size: 20px; } }
    @media (max-width: 1280px) {
      .contact-container__details {
        width: 350px; }
        .contact-container__details h2 {
          font-size: 22px; }
        .contact-container__details p {
          font-size: 16px; } }
    @media (max-width: 1024px) {
      .contact-container__details {
        width: 350px;
        margin-top: 20px;
        margin-bottom: 50px;
        margin-left: 20px; }
        .contact-container__details h2 {
          font-size: 22px; }
        .contact-container__details p {
          font-size: 16px; } }
    @media (max-width: 640px) {
      .contact-container__details {
        width: 75%; }
        .contact-container__details h2 {
          font-size: 18px; }
        .contact-container__details p {
          font-size: 14px; } }
  .contact-container__links-container {
    width: 200px; }
    .contact-container__links-container .some-links__icon {
      width: 50px;
      height: 50px; }
    @media (max-width: 1280px) {
      .contact-container__links-container {
        width: 150px; }
        .contact-container__links-container .some-links__icon {
          width: 40px;
          height: 40px; } }
    @media (max-width: 768px) {
      .contact-container__links-container {
        width: 150px; }
        .contact-container__links-container .some-links__icon {
          width: 40px;
          height: 40px; } }
  @media (max-width: 1024px) {
    .contact-container {
      display: flex;
      justify-content: center; } }
  @media (max-width: 640px) {
    .contact-container {
      margin: 55px 10px; } }

.nav-arrow-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 100px;
  height: 75px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  z-index: 10; }
  @media (min-width: 1824px) {
    .nav-arrow-button {
      width: 200px;
      height: 115px; } }
  .nav-arrow-button__label {
    font-size: 18px;
    font-weight: 300;
    transition: 0.3s; }
    @media (min-width: 1824px) {
      .nav-arrow-button__label {
        font-size: 22px; } }
    @media (max-width: 1280px) {
      .nav-arrow-button__label {
        font-size: 16px; } }
    @media (max-width: 768px) {
      .nav-arrow-button__label {
        font-size: 14px; } }
  .nav-arrow-button__arrow {
    width: 50px;
    height: 25px; }
    @media (min-width: 1824px) {
      .nav-arrow-button__arrow {
        width: 60px;
        height: 30px; } }
    @media (max-width: 1280px) {
      .nav-arrow-button__arrow {
        width: 40px;
        height: 20px; } }
    @media (max-width: 768px) {
      .nav-arrow-button__arrow {
        width: 30px;
        height: 15px; } }
    .nav-arrow-button__arrow__svg {
      width: 100%;
      height: 100%;
      transition: 0.3s; }
      .nav-arrow-button__arrow__svg polyline {
        stroke: #919191;
        stroke-width: 2; }
    .nav-arrow-button__arrow__fill {
      position: absolute;
      width: 100%;
      height: 0%;
      right: 0;
      stroke: #7dabbe;
      stroke-width: 2;
      transition: 0.3s; }
  .nav-arrow-button:hover .nav-arrow-button__label {
    font-size: 20px;
    text-shadow: 0px 4px 6px black; }
    @media (min-width: 1824px) {
      .nav-arrow-button:hover .nav-arrow-button__label {
        font-size: 24px; } }
    @media (max-width: 1280px) {
      .nav-arrow-button:hover .nav-arrow-button__label {
        font-size: 18px; } }
    @media (max-width: 768px) {
      .nav-arrow-button:hover .nav-arrow-button__label {
        font-size: 16px; } }
  .nav-arrow-button:hover .nav-arrow-button__arrow__svg {
    transform: scale(110%);
    filter: drop-shadow(0px 4px 6px black); }
  .nav-arrow-button:hover .nav-arrow-button__arrow__fill {
    transform: scale(110%);
    height: 100%; }
  .nav-arrow-button--up {
    flex-direction: column-reverse;
    padding-top: 1rem; }
    .nav-arrow-button--up .nav-arrow-button__arrow {
      transform: rotate(0deg);
      margin-bottom: -0.5rem; }
  .nav-arrow-button--down {
    flex-direction: column;
    margin-top: auto;
    padding-bottom: 1rem; }
    .nav-arrow-button--down .nav-arrow-button__arrow {
      transform: rotate(180deg);
      margin-top: -0.5rem; }
  .nav-arrow-button--left {
    flex-direction: row-reverse;
    left: 0;
    padding-left: 0.5rem; }
    .nav-arrow-button--left .nav-arrow-button__arrow {
      transform: rotate(270deg);
      margin-right: -0.5rem; }
  .nav-arrow-button--right {
    right: 0;
    padding-right: 0.5rem; }
    .nav-arrow-button--right .nav-arrow-button__arrow {
      transform: rotate(90deg);
      margin-left: -0.5rem; }
  .nav-arrow-button--left, .nav-arrow-button--right {
    position: fixed;
    align-self: flex-start;
    justify-content: flex-end;
    top: 45vh;
    bottom: 50vh; }

.arrow-fade-in {
  animation: arrow-fade-in 0.3s forwards; }

.arrow-fade-out {
  animation: arrow-fade-out 0.3s forwards; }

@keyframes arrow-fade-in {
  0% {
    filter: blur(4px);
    opacity: 0; }
  100% {
    filter: blur(0px);
    opacity: 1; } }

@keyframes arrow-fade-out {
  0% {
    filter: blur(0px);
    opacity: 1; }
  100% {
    filter: blur(4px);
    opacity: 0; } }

.nav-menu {
  position: fixed;
  display: none;
  flex-direction: row-reverse;
  top: 10px;
  left: -150px;
  transition: 0.3s;
  z-index: 5; }
  .nav-menu--show {
    left: 0px;
    z-index: 15; }
    .nav-menu--show .menu-list {
      box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.4); }
  .nav-menu__expand-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 36px;
    background: linear-gradient(56.35deg, #1c5c65 -90%, #484944 55%, #691d18 185%);
    margin-top: 5px;
    border-radius: 0px 25px 25px 0px;
    cursor: pointer;
    transition: 0.5s; }
    .nav-menu__expand-button:hover {
      padding-left: 7px;
      box-shadow: 4px 0px 10px 4px rgba(255, 255, 255, 0.068); }
    .nav-menu__expand-button__line-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 70%;
      margin-left: 2px; }
    .nav-menu__expand-button__line {
      width: 20px;
      height: 4px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.64) 0%, rgba(255, 255, 255, 0.48) 100%);
      margin-right: 8px;
      border-radius: 1px 5px 5px 1px; }

.nav-menu-list {
  width: 150px;
  height: fit-content;
  background: linear-gradient(56.35deg, #394e55 -190%, #2e2e2e 50%, #3a2929 190%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-left: none;
  border-radius: 0px 5px 5px 0px;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.3s; }
  .nav-menu-list__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    text-decoration: none;
    cursor: pointer; }
    .nav-menu-list__item:hover {
      background-color: rgba(98, 98, 98, 0.25); }
    .nav-menu-list__item h2 {
      font-size: 20px;
      font-weight: 300;
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: 10px; }
    .nav-menu-list__item--active {
      background-color: rgba(34, 34, 34, 0.75); }
  .nav-menu-list__separator {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3); }
  .nav-menu-list__submenu__item h2 {
    display: flex;
    align-items: center;
    font-size: 18px; }
  .nav-menu-list__submenu__separator {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.05); }
  .nav-menu-list__submenu__dot {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    margin-right: 10px;
    border-radius: 50%; }

@media (max-width: 640px) {
  .nav-menu {
    display: flex; } }

.some-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%; }
  .some-links__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(56.35deg, #1c5c65 -90%, #484944 55%, #691d18 185%);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s; }
    .some-links__icon svg {
      fill: rgba(225, 225, 225, 0.8);
      stroke: rgba(255, 255, 255, 0);
      transition: 0.2s; }
    .some-links__icon:hover {
      transform: scale(110%);
      box-shadow: 0px 0px 10px 4px rgba(255, 255, 255, 0.05); }
      .some-links__icon:hover svg {
        fill: rgba(225, 225, 225, 0);
        stroke: rgba(225, 225, 225, 0.8);
        stroke-width: 1px; }

.skill-carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-top: auto;
  margin-bottom: auto; }
  @media (max-width: 768px) {
    .skill-carousel-container {
      margin-bottom: 50px; } }
  @media (max-width: 420px) {
    .skill-carousel-container {
      margin-left: -25px;
      margin-right: -25px; } }
  .skill-carousel-container h2 {
    font-size: 28px;
    margin: 0;
    margin-top: 10px; }
    @media (min-width: 1824px) {
      .skill-carousel-container h2 {
        font-size: 32px; } }
    @media (max-width: 1280px) {
      .skill-carousel-container h2 {
        font-size: 22px; } }
    @media (max-width: 768px) {
      .skill-carousel-container h2 {
        font-size: 20px; } }
    @media (max-width: 420px) {
      .skill-carousel-container h2 {
        font-size: 18px; } }
  .skill-carousel-container__category {
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px; }
    @media (min-width: 1824px) {
      .skill-carousel-container__category {
        font-size: 28px; } }
    @media (max-width: 1280px) {
      .skill-carousel-container__category {
        font-size: 20px; } }
    @media (max-width: 420px) {
      .skill-carousel-container__category {
        font-size: 16px; } }
  .skill-carousel-container__cycle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    cursor: pointer;
    transition: 0.1s; }
    @media (min-width: 1824px) {
      .skill-carousel-container__cycle-button {
        width: 80px;
        height: 80px; } }
    @media (max-width: 768px) {
      .skill-carousel-container__cycle-button {
        width: 50px; } }
    @media (max-width: 420px) {
      .skill-carousel-container__cycle-button {
        width: 25px;
        height: 25px; } }
    .skill-carousel-container__cycle-button:hover {
      transform: scale(120%); }
      .skill-carousel-container__cycle-button:hover .skill-carousel-container__cycle-arrow {
        border-color: #91a7ad; }
  .skill-carousel-container__cycle-arrow {
    display: inline-block;
    height: 10px;
    width: 10px;
    padding: 3px;
    border: solid #656b6d;
    border-width: 0 3px 3px 0;
    transition: 0.1s; }
    @media (min-width: 1824px) {
      .skill-carousel-container__cycle-arrow {
        width: 15px;
        height: 15px; } }
    @media (max-width: 420px) {
      .skill-carousel-container__cycle-arrow {
        width: 7px;
        height: 7px; } }
    .skill-carousel-container__cycle-arrow--right {
      transform: rotate(-45deg); }
    .skill-carousel-container__cycle-arrow--left {
      transform: rotate(135deg); }
  .skill-carousel-container__nav-dots-container {
    display: flex;
    justify-content: space-between;
    width: 50px; }
    @media (max-width: 1280px) {
      .skill-carousel-container__nav-dots-container {
        display: flex;
        justify-content: space-between;
        width: 35px; } }
  .skill-carousel-container__nav-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.25);
    cursor: pointer; }
    @media (max-width: 1280px) {
      .skill-carousel-container__nav-dot {
        width: 7px;
        height: 7px; } }
    .skill-carousel-container__nav-dot--active {
      background-color: rgba(255, 255, 255, 0.75); }

.skills-container {
  display: flex;
  align-items: center;
  justify-content: center; }
  .skills-container__grid {
    display: grid;
    justify-content: center;
    grid-template-columns: 33% 33% 33%;
    width: 100%;
    max-width: 1100px; }
  .skills-container__skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 125px;
    margin: 15px; }
    @media (min-width: 1824px) {
      .skills-container__skill {
        width: 135px; } }
    @media (max-width: 1280px) {
      .skills-container__skill {
        width: 75px; } }
    @media (max-width: 768px) {
      .skills-container__skill {
        width: 85px;
        margin: 5px; } }
    @media (max-width: 640px) {
      .skills-container__skill {
        width: 75px;
        margin: 5px; } }
    @media (max-width: 420px) {
      .skills-container__skill {
        margin-left: 0;
        margin-right: 0; } }
    .skills-container__skill p {
      color: #afafaf;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      line-height: 20px;
      margin: 0; }
  .skills-container__skill-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #414141;
    background: linear-gradient(56.35deg, #1c5c65 -90%, #484944 55%, #691d18 185%);
    width: 50px;
    height: 50px;
    padding: 15px;
    border-radius: 50%;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25); }
    @media (min-width: 1824px) {
      .skills-container__skill-icon {
        width: 60px;
        height: 60px; } }
    @media (max-width: 1280px) {
      .skills-container__skill-icon {
        width: 40px;
        height: 40px; } }
    @media (max-width: 768px) {
      .skills-container__skill-icon {
        width: 30px;
        height: 30px; } }
  .skills-container__skill-icon-img {
    width: 100%;
    height: 100%; }
    .skills-container__skill-icon-img--large {
      width: 130%;
      height: 130%; }
    .skills-container__skill-icon-img--xlarge {
      width: 150%;
      height: 150%; }
  .skills-container__skill-name {
    font-family: Arial, Helvetica, sans-serif;
    color: #d1d1d1;
    font-size: 20px;
    text-align: center;
    margin: 15px 0px 10px 0px; }
    @media (max-width: 1280px) {
      .skills-container__skill-name {
        font-size: 14px; } }
    @media (max-width: 1024px) {
      .skills-container__skill-name {
        font-size: 12px; } }

.contact-form {
  width: 600px; }
  .contact-form__text {
    width: 600px;
    margin: 5px; }
    .contact-form__text h1 {
      color: #8cd6d6;
      font-size: 48px;
      font-weight: 400;
      background: linear-gradient(265deg, rgba(158, 79, 79, 0.5) -75%, #ffffff 50%, rgba(72, 137, 151, 0.5) 300%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: fit-content;
      margin: 0; }
    .contact-form__text p {
      font-family: Arial, Helvetica, sans-serif;
      color: rgba(255, 255, 255, 0.8);
      font-size: 22px;
      font-weight: 400;
      background: linear-gradient(265deg, rgba(158, 79, 79, 0.7) -150%, #ffffff 50%, rgba(72, 137, 151, 0.7) 250%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
    @media (min-width: 1824px) {
      .contact-form__text {
        width: 800px; }
        .contact-form__text h1 {
          font-size: 55px; }
        .contact-form__text p {
          font-size: 24px; } }
    @media (max-width: 1280px) {
      .contact-form__text {
        width: 480px; }
        .contact-form__text h1 {
          font-size: 38px; }
        .contact-form__text p {
          font-size: 18px; } }
    @media (max-width: 640px) {
      .contact-form__text {
        width: 100%; }
        .contact-form__text h1 {
          font-size: 30px; }
        .contact-form__text p {
          font-size: 16px; } }
  .contact-form__form {
    display: grid;
    grid-template-columns: 50% 50%; }
    .contact-form__form textarea {
      font-family: Arial;
      min-height: 150px;
      padding: 20px;
      resize: none; }
      @media (min-width: 1824px) {
        .contact-form__form textarea {
          min-height: 230px; } }
    .contact-form__form--hide {
      pointer-events: none;
      animation: contact-form__form--hide 2s forwards; }
  .contact-form__grid-item {
    position: relative;
    margin: 5px;
    padding: 0;
    grid-column: span 2; }
    .contact-form__grid-item--half {
      grid-column: span 1; }
      @media (max-width: 420px) {
        .contact-form__grid-item--half {
          grid-column: span 2; } }
  .contact-form__input-field {
    color: #c8c8c8;
    font-size: 16px;
    background: #2e2e2e;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    border: 0;
    box-sizing: border-box;
    outline: none; }
    .contact-form__input-field--disabled {
      color: gray; }
    .contact-form__input-field:focus + .contact-form__input-focus {
      width: 100%; }
    @media (min-width: 1824px) {
      .contact-form__input-field {
        height: 60px; } }
    @media (max-width: 1280px) {
      .contact-form__input-field {
        height: 45px;
        font-size: 14px; } }
  .contact-form__input-focus {
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid #7dabbe;
    transition: 0.5s; }
  .contact-form__button {
    height: 40px;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
    grid-column: span 1 !important;
    background: rgba(0, 0, 0, 0);
    border: 2px solid #4d8097;
    color: #7dabbe;
    font-size: 18px;
    font-weight: 400;
    text-shadow: 0px 1px 8px rgba(0, 0, 0, 0.616);
    cursor: pointer;
    transition: 0.2s; }
    .contact-form__button:hover {
      color: #d8d8d8;
      background: #4d8097; }
    .contact-form__button--disabled {
      color: gray !important;
      background-color: #2e2e2e !important;
      border-color: rgba(79, 79, 87, 0) !important;
      cursor: not-allowed !important; }
      .contact-form__button--disabled:hover {
        color: gray !important;
        background-color: #2e2e2e !important;
        border-color: #4f4f57 !important; }
    .contact-form__button--hide {
      animation: contact-form__button--hide 0.75s; }
    @media (min-width: 1824px) {
      .contact-form__button {
        height: 45px;
        font-size: 18px; } }
    @media (max-width: 1280px) {
      .contact-form__button {
        height: 35px;
        font-size: 16px; } }
  .contact-form__try-me {
    margin-right: auto;
    margin-left: 10px;
    color: #c2c3c2;
    border-color: #c2c3c2; }
    .contact-form__try-me:hover {
      color: #242424;
      background: #c2c3c2; }
  @media (min-width: 1824px) {
    .contact-form {
      width: 800px; } }
  @media (max-width: 1280px) {
    .contact-form {
      width: 500px; } }
  @media (max-width: 640px) {
    .contact-form {
      width: 100%; } }

@keyframes contact-form__form--hide {
  25% {
    opacity: 1; }
  26% {
    opacity: 0; }
  70% {
    opacity: 0; } }

@keyframes contact-form__button--hide {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.envelope-container {
  display: none;
  position: absolute;
  width: 600px;
  height: 280px;
  opacity: 0;
  overflow: hidden;
  z-index: 10; }
  .envelope-container polyline {
    fill: #c2c3c2;
    stroke: #242424;
    stroke-width: 2px; }
  @media (min-width: 1824px) {
    .envelope-container {
      width: 800px;
      height: 380px; } }
  @media (max-width: 1280px) {
    .envelope-container {
      width: 500px;
      height: 270px; } }
  @media (max-width: 640px) {
    .envelope-container {
      width: 100%; } }
  @media (max-width: 420px) {
    .envelope-container {
      height: 320px; } }

.envelope-top {
  position: absolute;
  height: 100%;
  top: -380px;
  left: 50%;
  transform: translateX(-50%); }

.envelope-bottom {
  position: absolute;
  height: 100%;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%); }

.envelope-left {
  position: absolute;
  width: 100%;
  top: 50%;
  left: -900px;
  transform: translate(0%, -50%); }

.envelope-right {
  position: absolute;
  width: 100%;
  top: 50%;
  right: 600px;
  transform: translate(50%, -50%); }

.animate-envelope {
  display: inline;
  animation: animate-envelope 1.5s; }
  .animate-envelope-top {
    animation: animate-envelope-top 0.75s forwards; }
  .animate-envelope-bottom {
    animation: animate-envelope-bottom 0.75s forwards; }
  .animate-envelope-left {
    animation: animate-envelope-left 0.75s forwards; }
  .animate-envelope-right {
    animation: animate-envelope-right 0.75s forwards; }

@keyframes animate-envelope {
  0% {
    opacity: 1; }
  50% {
    opacity: 1;
    transform: translateX(0%); }
  100% {
    opacity: 0;
    transform: translateX(-125%); } }

@keyframes animate-envelope-top {
  0% {
    top: -380px; }
  100% {
    top: -190px; } }

@keyframes animate-envelope-bottom {
  0% {
    bottom: 100px; }
  100% {
    bottom: 290px; } }

@keyframes animate-envelope-left {
  0% {
    transform: translate(0%, -50%); }
  100% {
    transform: translate(50%, -50%); } }

@keyframes animate-envelope-right {
  0% {
    right: 600px; }
  100% {
    right: 900px; } }

@media (min-width: 1824px) {
  @keyframes animate-envelope-top {
    0% {
      top: -380px; }
    100% {
      top: -125px; } }
  @keyframes animate-envelope-bottom {
    0% {
      top: 0px; }
    100% {
      top: -255px; } } }

@media (max-width: 1280px) {
  @keyframes animate-envelope-top {
    0% {
      top: -380px; }
    100% {
      top: -220px; } }
  @keyframes animate-envelope-bottom {
    0% {
      bottom: 100px; }
    100% {
      bottom: 270px; } } }

@media (max-width: 640px) {
  @keyframes animate-envelope-top {
    0% {
      top: -380px; }
    100% {
      top: -170px; } }
  @keyframes animate-envelope-bottom {
    0% {
      bottom: 50px; }
    100% {
      bottom: 270px; } } }

body {
  position: relative;
  font-family: 'Raleway', sans-serif;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(243.17deg, #352424 -8.58%, #252020 16.44%, #242424 52.1%, #252a2b 87.75%, #2e4142 111.52%);
  margin: 0;
  overflow-x: hidden; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

/* Scrollbar */
::-webkit-scrollbar {
  width: 7px; }

::-webkit-scrollbar-track {
  background: #181818; }

::-webkit-scrollbar-thumb {
  background: #202020;
  border-radius: 10px; }

::-webkit-scrollbar-thumb:hover {
  background: #555; }
